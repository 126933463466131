<template>
  <div>
    <div class="flex alc jcsb">
      <div class="public_title flex1">聊天设置</div>
      <el-button @click="save" type="primary">保存</el-button>
    </div>

    <el-form
      :model="form"
      :rules="rules"
      ref="ruleForm"
      label-width="160px"
      label-position="top"
      v-loading="loading"
    >
      <el-form-item label="是否启用聊天自动回复" prop="status">
        <el-radio-group v-model="form.status">
          <el-radio label="启用">启用</el-radio>
          <el-radio label="未启用">未启用</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="回复内容" prop="content">
        <el-input
          type="textarea"
          :rows="4"
          placeholder="请输入自动回复话术..."
          v-model="form.content"
        >
        </el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
/* 个人中心-聊天设置 */
import { getChatSetting, saveChatSetting } from "@/api/personalCenter";
import local from "@/utils/local";

export default {
  data() {
    return {
      form: { status: "", content: "" },
      rules: {
        status: [
          {
            required: true,
            message: "请选择是否启用自动回复",
            trigger: "change",
          },
        ],
        content: [
          { required: true, message: "请输入自动回复话术", trigger: "blur" },
        ],
      },
      loading: false,
    };
  },

  methods: {
    /* 获取数据 */
    // 获取聊天设置
    async getData() {
      this.loading = true;
      const params = {
        wxUserId: local.get("userInfo").id,
      };
      const { code, data, msg } = await getChatSetting(params);
      if (code !== 0) return this.$message.error(msg);
      this.form = data;
      this.loading = false;
    },

    /* 操作 */
    // 保存设置
    save() {
      const params = { ...this.form };
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          // 保存聊天设置
          const res = await saveChatSetting(params);
          if (res.code == 0) {
            this.$message.success("保存成功！");
          }
        } else {
          return false;
        }
      });
    },

    /* 初始化 */
    __init__() {
      this.getData();
    },
  },

  created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
/deep/.el-form-item__label {
  color: #333333;
  font-weight: bold;
  font-size: 15px;
}

/deep/.el-button--primary {
  background-color: #4f7bff;
  border-color: #4f7bff;
}
</style>
